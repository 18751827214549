:root {
  --dark-navy: #242629;
  --navy: #16161a;
  --light-navy: #242629;
  --lightest-navy: #7f5af0;
  --navy-shadow: rgba(2, 12, 27, 0.7);
  --slate: #8892b0;
  --light-slate: #a8b2d1;
  --lightest-slate: #ccd6f6;
  --white: #e6f1ff;
  --green-bright: #00ced1;
  --green-tint: rgba(100, 255, 218, 0.1);
}
